import { browseFile } from "nq";
import BaseListPresenter from "../../base/BaseListPresenter";

class DeductiblePresenter extends BaseListPresenter {
  constructor(
    view,
    findObjectUseCase,
    countObjectUseCase,
    upsertUseCase,
    deleteObjectUseCase
  ) {
    super(
      view,
      findObjectUseCase,
      countObjectUseCase,
      upsertUseCase,
      deleteObjectUseCase
    );
    this.upsertUseCase = upsertUseCase;
    this.deleteObjectUseCase = deleteObjectUseCase;
  }

  init() {
    this.limit = 20;
    this.where = {};
    this.search = {};
    this.filter = {};
    this.filterDate = {};
    this.filterList = {};
    this.include = ["all", "vehicleType"];
    this.keys = [];
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }

  createQuery() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
        ...this.filter,
        ...this.filterDate,
        ...this.filterList,
      },

      include: this.include,
    };
    if (this.sort) {
      query.sort = this.sort;
    }
    const keys = this.keys || this.view.getKeys() || [];
    if (keys.length > 0) {
      query.keys = keys;
    }
    return query;
  }

  // async findObjects() {
  //   const user = this.view.getCurrentUser();
  //   const collection = this.view.getCollectionName();
  //   let query;

  //   query = this.createQuery();

  //   try {
  //     this.showProgress();
  //     this.findObjectUseCase.abort();
  //     const objects = await this.findObjectUseCase.execute(collection, query);
  //     console.log("objects", objects);
  //     const neObjects = await this.findObjectUseCase.execute(
  //       "vehicle_type",
  //       query
  //     );
  //     this.objects = this.objects.concat(objects);
  //     this.view.setTotal(this.objects.length);
  //     this.view.setObjects(this.objects);
  //     this.hideProgress();
  //   } catch (error) {
  //     this.hideProgress();
  //     this.view.showError(error);
  //   }
  //   this.progress = false;
  // }

  async findObjects() {
    const user = this.view.getCurrentUser();
    const collection = this.view.getCollectionName();
    let query;

    query = this.createQuery();

    try {
      this.showProgress();
      this.findObjectUseCase.abort();

      // Fetch objects from the primary collection
      const objects = await this.findObjectUseCase.execute(collection, query);

      // Extract unique vehicleType IDs from objects
      const vehicleTypeIds = [
        ...new Set(objects.map((obj) => obj.vehicleType.id)),
      ];

      // Build a query for vehicle_type collection
      const vehicleTypeQuery = {
        id: { $in: vehicleTypeIds },
      };

      // Fetch corresponding vehicle types
      const vehicleTypes = await this.findObjectUseCase.execute(
        "vehicle_type",
        vehicleTypeQuery
      );

      // Map vehicle type IDs to names for easy lookup
      const vehicleTypeMap = vehicleTypes.reduce((map, vt) => {
        map[vt.id] = vt.name;
        return map;
      }, {});

      // Add the name to each object's vehicleType
      this.objects = objects.map((obj) => ({
        ...obj,
        vehicleType: {
          ...obj.vehicleType,
          name: vehicleTypeMap[obj.vehicleType.id] || "Unknown", // Default to "Unknown" if not found
        },
      }));

      // Update the view
      this.view.setTotal(this.objects.length);
      this.view.setObjects(this.objects);
      this.hideProgress();
    } catch (error) {
      this.hideProgress();
      this.view.showError(error);
    }

    this.progress = false;
  }

  filterListing(where) {
    this.reset();
    this.filterList = where;
    this.getObjects();
  }

  onChangeDate(where) {
    this.filterDate = where;
    this.getObjects();
  }

  onChangeObject(objects) {
    this.change = objects;
    this.object = objects;
    console.log("this.change", this.change);
    console.log("this.object", this.object);
  }

  onClickAdd() {
    const collection = this.view.getCollectionName();

    this.view.navigateTo("/deductible/form");
  }

  onClickItem(index) {
    const object = this.objects[index];
    const collection = this.view.getCollectionName();
    this.view.navigateTo("/deductible/form/" + object.id);
  }
  onClickExport() {
    this.view.exportPDF();
  }

  async onClickStatus(index, status) {
    const collection = this.view.getCollectionName();
    const object = this.objects[index];
    object.status = status;
    try {
      const data = await this.upsertUseCase.execute(collection, object);
      if (data.status === object.status) {
        this.objects[index] = object;
        this.view.setObjects(this.objects);
      }
    } catch (error) {
      this.view.showError(error);
    }
  }
}

export default DeductiblePresenter;
