import React from "react";
import withRouter from "../../../withRouter";
import QuatationFormPresenter from "./QuatationFormPresenter";
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../../usecases/object";
import NavBar from "../../../components/navbar";
import FindObjectUseCase from "../../../usecases/object/FindObjectUseCase";
import BaseFormPage from "../../../base/BaseFormPage";
import Select from "react-select";

class QuatationFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {},
      motorCarYear: [],
      yearConfig: [],
      isClearable: false,
      isClearableYear: false,
      isClearBrand: false,
      typess: false,
      brandUndefined: null,
      isShow: false,
      isClearEdit: false,

      advanced: false,
      sendData: {
        year: "",
        brand: "",
        model: "",
        capacity: "",
        roadAssist: false,
        commissionRate: "",
        vehicleType: "",
        carBrand: "",
        origVehicle: "",
      },

      id: "",

      fvm: "",
      carCompanyList: [],
      carModelList: [],
      make: [],
      carVariantList: [],
      premiumRateList: [],
      isComputation: false,
      isReset: false,
      vehicleTypeList: [],
      vehicleTypeListVal: [],
      displayVehicleType: "",
      pool: [],
      setStateYear: "",
      makeObject: [],
    };
    this.handleBackComputer = this.handleBackComputer.bind(this);
    this.presenter = new QuatationFormPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      findObjectUseCase()
    );
  }

  getCollectionName() {
    return "motor_cars";
  }

  onClickAdvance() {
    this.setState({ advanced: !this.state.advanced });
  }

  async componentDidMount() {
    const params = this.getParams();

    this.initialize();
    const userLogin = this.getCurrentUser();
    console.log("SL", userLogin);
    const get = new FindObjectUseCase();
    const motorCar = await get.execute("motor_cars");
    const yearConfig = await get.execute("configuration");
    this.setState({ yearConfig });
    this.setState({ motorCarYear: motorCar });
    console.log("mc", motorCar);
    const query = {
      where: {
        status: "ACTIVE",
      },
    };
    const res = userLogin.roles.some((role) => role.id === "Standard Agent")
      ? await get.execute("standard_odta", query)
      : await get.execute("special_odta", query);
    const vehicleTypes = await get.execute("vehicle_type");
    const DEDUCTIBLE = await get.execute("deductible_module");
    const val = DEDUCTIBLE.map((item) => item.deductible);

    console.log("resres", res);
    // const newRes = res.map((res) =>
    //   res.specialAgent.filter((sa) => sa.id === userLogin.id)
    // );
    const newRes = res?.filter((item) =>
      item?.specialAgent?.some((sa) => sa.id === userLogin.id)
    );
    const newResGroup = res?.filter((item) =>
      item?.group?.some((sa) => sa.id === userLogin.group.id)
    );
    console.log("newRes", newRes);
    console.log("newResGroup", newResGroup);
    const combinedRes = [...newRes, ...newResGroup];

    // const premiums = newRes.map(
    //   (r) => `${r.premiumRateAON} / ${r.commissionRateODTON}%`
    // );
    const uniqueCombinedRes = Array.from(
      new Map(combinedRes.map((item) => [item.id, item])).values()
    );

    const premiums =
      userLogin.roles[0].id === "Standard Agent"
        ? res.map((r) => `${r.premiumRateAON} / ${r.commissionRateODTON}%`)
        : uniqueCombinedRes.map(
            (r) => `${r.premiumRateAON} / ${r.commissionRateODTON}%`
          );

    // console.log("RATES USER ROLES", uniqueCombinedRes);

    this.setState({
      premiumRateList: premiums,
      vehicleTypeList: vehicleTypes,
      vehicleTypeListVal: val,
    });

    // this.getVariant();

    const id = this.getParams().id;
    if (id) {
      const quote = await get.execute("quotations", { where: { id: id } });

      const whatToDisplay = DEDUCTIBLE.filter(
        (item) => item.deductible === quote[0]?.vehicleType
      );

      if (quote) {
        this.setState((prevState) => ({
          id: id,
          sendData: {
            ...prevState.sendData,
            year: quote[0]?.year,
            brand: quote[0]?.brand,
            capacity: quote[0]?.capacity,
            model: quote[0]?.model,
            commissionRate: quote[0]?.commissionRate,
            roadAssist: quote[0]?.roadAssist,
            vehicleType: quote[0]?.vehicleType,
          },
          fvm: quote[0]?.fvm,
          displayVehicleType: whatToDisplay[0].deductible.includes("3")
            ? "SUV/AUV/WAGON/PICK-UP/VAN"
            : "SEDAN",
        }));
      }
    }
  }

  async initialize() {
    try {
      console.log("Initialization started");
      // await this.getBrand();
      // await this.getCarVariant();
      // await this.getModel();
      // await this.getCarVariant();
      // ...rest of your async code
    } catch (error) {
      console.error("Error during initialization:", error);
    }
  }

  getAcl() {
    const user = this.getCurrentUser();
    return {
      read: ["*", user.id, "role:admin"],
      write: [user.id, "role:admin"],
    };
  }

  async getBrand(year) {
    const quoteInfo = JSON.parse(localStorage.getItem("quote"));
    const chooseYear = year ?? quoteInfo.year;
    this.setState({ setStateYear: chooseYear });
    const get = new FindObjectUseCase();
    const query = {
      where: {},
    };
    query.where[chooseYear] = { $ne: [0] };
    const res = await get.execute("motor_cars", query);

    const filtered = res.filter(
      (item) =>
        item[chooseYear] !== 0 && item[chooseYear] !== "" && item[chooseYear]
    );
    console.log("setset", filtered);
    const carCompanySet = new Set(filtered.map((car) => car.carCompany));

    const _carCompanyList = Array.from(carCompanySet);

    console.log(
      "_carCompanyList_carCompanyList_carCompanyList_carCompanyList_carCompanyList",
      _carCompanyList
    );

    sessionStorage.setItem("pool", JSON.stringify(filtered));

    this.setState({ carCompanyList: _carCompanyList, pool: filtered });
  }

  async getModel(brand) {
    console.log("brand", brand);
    const quoteInfo = JSON.parse(localStorage.getItem("quote"));
    const sessionPool = JSON.parse(sessionStorage.getItem("pool"));
    console.log("qweqweqwe", quoteInfo);
    console.log("sessionPool", sessionPool);
    const chooseBrand = brand ?? quoteInfo?.carCompany;
    console.log("chooseBrand", chooseBrand);

    const choosePool = sessionPool ?? this.state?.pool;
    console.log("this.state.pool", choosePool);

    const aModelList = choosePool.filter(
      (item) => item.carCompany == chooseBrand
    );

    const carModelSet = new Set(aModelList.map((car) => car.make));
    const _carModelList = Array.from(carModelSet);

    console.log("carModelList", _carModelList);

    const query = {
      where: {
        // make: { $in: _carModelList },
        carCompany: { $in: [chooseBrand] },
      },
    };

    // query.where[this.state.setStateYear] = { $ne: [0] };
    const filtered = await findObjectUseCase().execute("motor_cars", query);
    console.log("FIFIFIFIIFF", filtered);

    const newFiltered = filtered.filter(
      (item) =>
        item[this.state.setStateYear] !== 0 &&
        item[this.state.setStateYear] !== "" &&
        item[this.state.setStateYear]
    );

    console.log("newFiltered", newFiltered);

    const modelSet = new Set(newFiltered.map((car) => car.carVariant));
    const _modelList = Array.from(modelSet);

    sessionStorage.setItem("makeObject", JSON.stringify(newFiltered));

    this.setState({ make: _carModelList, makeObject: newFiltered });
  }

  async getCarVariant(newMake) {
    console.log("newnenwe", newMake);
    const quoteInfo = JSON.parse(localStorage.getItem("quote"));
    const sessionMakeObject = JSON.parse(sessionStorage.getItem("makeObject"));
    const chooseNewMake = newMake ?? quoteInfo.brand;
    const chooseMakeObject = sessionMakeObject ?? this.state.makeObject;
    const aModelList = chooseMakeObject.filter(
      (item) => item.make == chooseNewMake && item.status === "ACTIVE"
    );

    this.setState({ newCapacity: aModelList });

    const modelSet = new Set(aModelList.map((car) => car.carVariant));
    const _modelList = Array.from(modelSet);

    console.log("MWEEB", chooseMakeObject);

    this.setState({ carModelList: _modelList });
  }

  async getVariant(model) {
    console.log("MODEL MODEL", model);
    const getModel = await findObjectUseCase().execute("motor_cars", {
      where: { carVariant: model },
    });
    console.log("gggg", getModel);
    const getVehicleType = await findObjectUseCase().execute("vehicle_type", {
      where: { id: getModel[0].vehicleType.id },
    });
    console.log("getegetegete", getVehicleType);
    const origVehicleType = getVehicleType[0].name;
    const origVehicleTypeId = getVehicleType[0].id;

    const getCorrectDeduc = await findObjectUseCase().execute(
      "deductible_module",
      { where: { vehicleType: { id: origVehicleTypeId } } }
    );
    const getCorrectDeducName = getCorrectDeduc[0].deductible;
    console.log("dmdmdmd", getCorrectDeducName);
    const { pool, sendData } = this.state;

    const normalizeSpaces = (str) => str.trim().replace(/\s+/g, " ");

    const aVariantList = pool.filter((item) => {
      return normalizeSpaces(item.carVariant) === normalizeSpaces(model);
    });

    let newFvm;
    if (aVariantList.length > 0 && sendData.year) {
      newFvm = aVariantList[0][sendData.year];
    } else {
      console.warn("aVariantList[0] or sendData.year is not defined");
    }

    // const isLowerThanSixSeats = this.state.sendData.capacity <= 5;
    // const filteredVehicle = isLowerThanSixSeats
    //   ? this.state.vehicleTypeListVal[0]
    //   : this.state.vehicleTypeListVal[1];
    // const show = isLowerThanSixSeats
    //   ? this.state.vehicleTypeList[0]
    //   : this.state.vehicleTypeList[1];

    this.setState({ carVariantList: aVariantList, fvm: newFvm || "" });
    this.setState((prevState) => ({
      sendData: {
        ...prevState.sendData,
        capacity: aVariantList[0]?.capacity,
        origVehicle: origVehicleType,
        origDeduc: getCorrectDeducName,
      },
      // displayVehicleType: show.name,
    }));
  }

  handleYearChange = (e) => {
    // this.setState({ isClearable: false });

    this.setState((prevState) => ({
      isClearableYear: !prevState.isClearableYear,
    }));

    const newYear = !e.target?.value
      ? this.state.sendData.year
      : e.target.value;

    if (this.state.sendData.year === "") {
      this.setState(
        (prevState) => ({
          sendData: {
            ...prevState.sendData,
            year: newYear,
            brand: "",
            model: "",
            capacity: "",
            vehicleType: "",
            carBrand: "",
          },
          displayVehicleType: "",
        }),
        () => {
          this.getBrand(newYear);
        }
      );
    } else if (this.state.sendData.year !== newYear) {
      this.setState({ brandUndefined: undefined });
      this.setState({ isClearEdit: true });
      this.setState({ isClearBrand: true });

      this.setState(
        (prevState) => ({
          sendData: {
            ...prevState.sendData,
            year: newYear,
            brand: "",
            model: "",
            capacity: "",
            vehicleType: "",
            carBrand: "",
          },
          displayVehicleType: "",
        }),
        () => {
          this.getBrand(newYear);
        }
      );
      return;
    }
  };

  //   handleModelChange = (e) => {
  //     const newModel = e.target?.value || this.state.sendData.model;

  //     const isLowerThanSixSeats = this.state.sendData.capacity <= 5;
  //     const filteredVehicle = isLowerThanSixSeats
  //       ? this.state.vehicleTypeListVal[0]
  //       : this.state.vehicleTypeListVal[1];
  //     const show = isLowerThanSixSeats
  //       ? this.state.vehicleTypeList[0]
  //       : this.state.vehicleTypeList[1];

  //     console.log(filteredVehicle);
  //     console.log(show);

  //     this.setState(
  //       (prevState) => ({
  //         sendData: {
  //           ...prevState.sendData,
  //           model: newModel,
  //           vehicleType: filteredVehicle,
  //         },
  //         displayVehicleType: show.name,
  //       }),
  //       () => {
  //         this.getVariant(newModel);
  //       }
  //     );
  //   };

  // handleBrandChange = (selectedOption) => {
  //   const newBrand = selectedOption ? selectedOption.value : "";
  //   console.log("Selected brand:", newBrand);
  //   this.setState(
  //     (prevState) => ({
  //       sendData: {
  //         ...prevState.sendData,
  //         brand: newBrand,
  //         carBrand: newBrand,
  //         make: "", // Clear make
  //         model: "", // Clear model
  //         capacity: "", // Clear capacity
  //         vehicleType: "", // Clear vehicleType
  //       },
  //       displayVehicleType: "", // Clear displayVehicleType
  //     }),
  //     () => {
  //       if (newBrand) {
  //         this.getModel(newBrand);
  //       }
  //     }
  //   );
  // };

  // handleMakeChange = (selectedOption) => {
  //   const newMake = selectedOption ? selectedOption.value : "";
  //   console.log("Selected make:", newMake);
  //   this.setState(
  //     (prevState) => ({
  //       sendData: {
  //         ...prevState.sendData,
  //         make: newMake,
  //         model: "", // Clear model
  //         capacity: "", // Clear capacity
  //         vehicleType: "", // Clear vehicleType
  //       },
  //       displayVehicleType: "", // Clear displayVehicleType
  //     }),
  //     () => {
  //       if (newMake) {
  //         this.getCarVariant(newMake);
  //       }
  //     }
  //   );
  // };

  // handleModelChange = (selectedOption) => {
  //   const newModel = selectedOption ? selectedOption.value : "";
  //   const isLowerThanSixSeats = this.state.sendData.capacity <= 5;
  //   const filteredVehicle = isLowerThanSixSeats
  //     ? this.state.vehicleTypeListVal[0]
  //     : this.state.vehicleTypeListVal[1];
  //   const show = isLowerThanSixSeats
  //     ? this.state.vehicleTypeList[0]
  //     : this.state.vehicleTypeList[1];

  //   console.log("Filtered vehicle:", filteredVehicle);
  //   console.log("Display vehicle type:", show);

  //   this.setState(
  //     (prevState) => ({
  //       sendData: {
  //         ...prevState.sendData,
  //         model: newModel,
  //         vehicleType: filteredVehicle,
  //       },
  //       displayVehicleType: show.name,
  //     }),
  //     () => {
  //       if (newModel) {
  //         this.getVariant(newModel);
  //       }
  //     }
  //   );
  // };

  handleModelChange = (selectedOption) => {
    this.setState({ isClearEdit: false });
    this.setState({ isShow: true });
    this.setState({ isClearable: false });

    const newModel = selectedOption ? selectedOption.value : "";
    if (!selectedOption) {
      this.setState({ typess: true });
      this.setState((prevState) => ({
        sendData: {
          ...prevState.sendData,
          capacity: "",
          model: "",
        },
        displayVehicleType: "",
      }));
      return;
    }
    console.log("SEND-DATA", this.state.sendData.capacity);

    const seatsMatch = newModel.match(/(\d+)-Seater/);

    const numberOfSeats = seatsMatch ? parseInt(seatsMatch[1], 10) : null;

    // Now you can use numberOfSeats in your logic
    const isLowerThanSixSeats = numberOfSeats <= 5;
    const filteredVehicle = isLowerThanSixSeats
      ? this.state.vehicleTypeListVal[0]
      : this.state.vehicleTypeListVal[1];
    const show = isLowerThanSixSeats
      ? this.state.vehicleTypeList[0]
      : this.state.vehicleTypeList[1];

    console.log(filteredVehicle);
    console.log("SHOOOOOOOOOOW", show);
    console.log("model", newModel);

    this.setState(
      (prevState) => ({
        sendData: {
          ...prevState.sendData,
          model: newModel,
          vehicleType: filteredVehicle,
        },
        displayVehicleType: show.name,
      }),
      () => {
        this.getVariant(newModel);
      }
    );
  };

  // handleBrandChange = (selectedOption) => {
  //   const newBrand = selectedOption ? selectedOption.value : "";
  //   console.log("hehehe", newBrand);
  //   this.setState(
  //     (prevState) => ({
  //       sendData: {
  //         ...prevState.sendData,
  //         brand: newBrand,
  //         carBrand: newBrand,
  //       },
  //     }),
  //     () => {
  //       this.getModel(newBrand);
  //     }
  //   );
  // };

  // handleBrandChange = (selectedOption) => {
  //   this.setState({ isClearable: true });
  //   this.setState({ brandUndefined: "" });
  //   const newBrand = selectedOption ? selectedOption.value : "";
  //   if (!selectedOption) {
  //     this.setState((prevState) => ({
  //       sendData: {
  //         ...prevState.sendData,
  //         brand: "",
  //         model: "",
  //         capacity: "",

  //         // carBrand: newBrand,
  //       },
  //       displayVehicleType: "",
  //     }));
  //   }
  //   console.log("hehehe", newBrand);
  //   this.setState(
  //     (prevState) => ({
  //       sendData: {
  //         ...prevState.sendData,
  //         brand: newBrand,
  //         carBrand: newBrand,
  //       },
  //     }),
  //     () => {
  //       this.getModel(newBrand);
  //     }
  //   );
  // };

  handleBrandChange = (selectedOption) => {
    this.setState({ isClearable: true });
    this.setState({ isClearEdit: false });

    this.setState({ brandUndefined: "" });

    const newBrand = selectedOption ? selectedOption.value : "";

    console.log("newsss", newBrand);

    // If no brand is selected, reset relevant fields
    if (!selectedOption) {
      this.setState((prevState) => ({
        sendData: {
          ...prevState.sendData,
          brand: "",
          model: "",
          capacity: "",
          vehicleType: "",
          carBrand: "",
        },
        displayVehicleType: "", // Reset displayVehicleType as well
      }));
      return; // Exit early if no selection
    }

    // When a new brand is selected, reset model, capacity, vehicleType, and carBrand
    this.setState(
      (prevState) => ({
        sendData: {
          ...prevState.sendData,
          brand: newBrand, // Set the new brand
          carBrand: newBrand, // Set the new carBrand
          model: "", // Reset model
          capacity: "", // Reset capacity
          vehicleType: "", // Reset vehicleType
        },
        displayVehicleType: "", // Reset displayVehicleType
        isClearEdit: true,
      }),
      () => {
        this.getModel(newBrand); // Fetch the model data for the selected brand
      }
    );
  };

  // handleMakeChange = (selectedOption) => {
  //   this.setState({ isClearable: false });
  //   const newMake = selectedOption ? selectedOption.value : "";
  //   if (!selectedOption) {
  //     this.setState({ typess: true });
  //     this.setState((prevState) => ({
  //       sendData: {
  //         ...prevState.sendData,
  //         brand: "",
  //         model: "",
  //         capacity: "",

  //         // carBrand: newBrand,
  //       },
  //       displayVehicleType: "",
  //     }));
  //   }
  //   if (!selectedOption) {
  //     this.setState((prevState) => ({
  //       sendData: {
  //         ...prevState.sendData,
  //         brand: "",
  //         model: "",
  //         capacity: "",

  //         // carBrand: newBrand,
  //       },
  //       displayVehicleType: "",
  //     }));
  //   }
  //   this.setState(
  //     (prevState) => ({
  //       sendData: {
  //         ...prevState.sendData,
  //         brand: newMake,
  //       },
  //     }),
  //     () => {
  //       this.getCarVariant(newMake);
  //     }
  //   );
  // };

  handleMakeChange = (selectedOption) => {
    this.setState({ isClearable: false });

    const newMake = selectedOption ? selectedOption.value : "";

    // If no option is selected, reset the fields
    if (!selectedOption) {
      this.setState({ typess: true });
      this.setState((prevState) => ({
        sendData: {
          ...prevState.sendData,
          brand: "", // Reset brand
          model: "", // Reset model
          capacity: "", // Reset capacity
        },
        displayVehicleType: "", // Reset displayVehicleType
      }));
      return; // Early exit to avoid further execution
    }

    // When a new make is selected, update the brand and reset fields if needed
    this.setState(
      (prevState) => ({
        sendData: {
          ...prevState.sendData,
          brand: newMake, // Set the new make/brand
          capacity: "",
        },
        isClearEdit: true,
      }),
      () => {
        this.getCarVariant(newMake); // Fetch car variants for the selected make
      }
    );
  };

  handleVariantChange = (e) => {
    const variant = this.state.sendData.variant
      ? this.state.sendData.variant
      : this.state.carVariantList[0];
    this.setState((prevState) => ({
      sendData: {
        ...prevState.sendData,
        variant: variant,
      },
    }));
  };

  handleBackComputer = (e) => {
    this.setState({ isComputation: false });
  };

  resetForm() {
    this.setState((prev) => ({
      sendData: {
        ...prev.sendData,
        year: "",
        brand: "",
        model: "",
        capacity: "",
        roadAssist: false,
        commissionRate: "",
        vehicleType: "",
      },
      displayVehicleType: "",
      fvm: "",
      isReset: true,
    }));
  }

  renderYearOptions = () => {
    const { motorCarYear } = this.state;
    return Object.keys(motorCarYear).map((year) => {
      return (
        <option key={year} value={year}>
          {year}
        </option>
      );
    });
  };

  render() {
    const schema = this.getSchema(this.getCollectionName());
    const { carCompanyList, sendData } = this.state;
    const params = this.getParams();
    console.log("carcar", carCompanyList);
    const quoteInfo = JSON.parse(localStorage.getItem("quote"));
    console.log("quoteInfo", quoteInfo);

    const brandOptions = carCompanyList
      .sort((a, b) => a.localeCompare(b)) // Sort the carCompanyList alphabetically
      .map((car) => ({
        value: car,
        label: car,
      }));

    console.log("ssss", brandOptions);
    console.log("hehe", sendData.brand);

    // const selectedBrand = brandOptions.find((option) =>
    //   option.value === params.id ? quoteInfo.carCompany : sendData.brand
    // );
    // const selectedBrand = brandOptions.find(
    //   (option) =>
    //     option.value === (params.id ? quoteInfo.carCompany : sendData.brand)
    // );
    const selectedBrand = brandOptions.find((option) => {
      console.log("option", option.value);

      // If params.id exists, use quoteInfo.carCompany
      if (params.id && [""].includes(option.value)) {
        // When params.id exists, compare option.value to quoteInfo.carCompany
        return option.value === quoteInfo.carCompany;
      }

      // If params.id is present but you want to use sendData.brand after updating
      if (!params.id) {
        // Compare option.value with sendData.brand when no params.id
        return option.value === sendData.brand;
      }

      // Default to comparing sendData.brand if no other conditions met
      return option.value === sendData.brand;
    });

    // const selectedBrand = brandOptions.find((option) => {
    //   console.log("option", option.value);
    //   // If there's a params.id and quoteInfo.carCompany exists, use it
    //   if (params.id && option.value === "Audi") {
    //     // alert("true");
    //     return option.value === sendData.brand;
    //   }
    //   // Otherwise, use sendData.brand

    //   return option.value === quoteInfo.carCompany;
    // });

    console.log("brandOptions", selectedBrand);
    // If makeOptions is empty, set selectedMake to null
    const valueOptionsBrand =
      brandOptions.length > 0
        ? selectedBrand
        : params?.id
        ? {
            value: quoteInfo?.carCompany ?? sendData.brand,
            label: quoteInfo?.carCompany ?? sendData.brand,
          }
        : null;
    console.log("VOB", valueOptionsBrand);
    console.log("this.state.brandUndefined", this.state.brandUndefined);

    const makeOptions = this.state.make
      .sort((a, b) => a.localeCompare(b)) // Sort the make array alphabetically
      .map((make) => ({
        value: make,
        label: make,
      }));

    const selectedMake = makeOptions.find(
      (option) => option.value === sendData.brand
    );

    console.log("make lenght", selectedMake);
    console.log("params", params);

    // If makeOptions is empty, set selectedMake to null
    const value =
      makeOptions.length > 0
        ? selectedMake
        : params?.id
        ? {
            value: quoteInfo.brand,
            label: quoteInfo.brand,
          }
        : null;

    console.log("HAHAHHA VALUE", value);

    const variantOptions = this.state.carModelList
      .sort((a, b) => a.localeCompare(b)) // Sort the carModelList alphabetically
      .map((car) => ({
        value: car,
        label: car,
      }));

    const selectedVariant = variantOptions.find(
      (option) => option.value === this.state.sendData.model
    );

    // If makeOptions is empty, set selectedMake to null
    const valueOptions = variantOptions.length > 0 ? selectedVariant : null;

    console.log("MAKE valie", value);

    console.log("MAKE OPTIONS", makeOptions);

    console.log("object quot : ", this.state.object);

    const user = this.getCurrentUser();
    const yearOptions = this.state.motorCarYear
      .map((car) => Object.keys(car).filter((key) => /^\d{4}$/.test(key)))
      .flat();
    const uniqueYears = Array.from(new Set(yearOptions));
    const configYears = this.state.yearConfig;
    console.log("Config Years", configYears);
    console.log("Unique Years", uniqueYears);

    // Extract year_model from configYears
    const yearModel = configYears[0]?.year_model;

    // Get the latest year from uniqueYears
    const latestYear = Math.max(...uniqueYears.map((year) => parseInt(year)));

    // Filter uniqueYears based on yearModel
    const filteredYears = uniqueYears.filter(
      (year) => parseInt(year) >= latestYear - yearModel + 1
    );

    console.log("VT", this.state.sendData.capacity);
    console.log("this.state.isClearEdit", this.state.isClearEdit);
    console.log("this.state.isClearable", this.state.isClearable);

    const sedan = this.state.isClearEdit
      ? ""
      : this.state.isClearable
      ? ""
      : this.state.typess
      ? ""
      : "SEDAN";
    const suv = this.state.isClearEdit
      ? ""
      : this.state.isClearable
      ? ""
      : this.state.typess
      ? ""
      : "SUV/AUV/WAGON/PICK-UP/VAN";

    if (!schema) return <h1>no schema</h1>;
    const label = this.getObjectId() === undefined ? "Add New " : "Edit ";
    return (
      <>
        <NavBar
          action={() => (
            <div className="dropdown dropstart d-inline-block">
              <i
                role="button"
                data-bs-toggle="dropdown"
                className="bi bi-three-dots-vertical"
              ></i>
              <div className="dropdown-menu fs-xs">
                <button
                  onClick={this.onClickAdvance.bind(this)}
                  className="dropdown-item py-3"
                >
                  <i className="bi bi-gear pe-2" />
                  Toggle Advance
                </button>
              </div>
            </div>
          )}
        />
        <div className="overflow-auto">
          <div className="h-100">
            <div className="p-3 p-lg-4">
              <h1 className="fw-bold mt-3 text-capitalize">
                Create new Quotation
              </h1>
              <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                <form onSubmit={this.onSubmitForm.bind(this)}>
                  <div className="row g-3 py-4">
                    <div>
                      <label>Year</label>
                      <select
                        required
                        className="form-select"
                        value={this.state.sendData.year}
                        name="year"
                        onChange={this.handleYearChange}
                      >
                        <option>Select year</option>
                        {filteredYears.sort().map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <label>Brand</label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={false}
                        isSearchable={true}
                        name="brand"
                        options={brandOptions}
                        onChange={this.handleBrandChange}
                        value={
                          valueOptionsBrand == this.state.brandUndefined
                            ? ""
                            : this.state.brandUndefined === undefined
                            ? ""
                            : valueOptionsBrand
                        }
                        placeholder={
                          this.state.isClearBrand
                            ? ""
                            : params.id
                            ? quoteInfo.carCompany
                            : "Select Brand"
                        }
                        required
                      />
                    </div>

                    <div>
                      <label>Make</label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={false}
                        isSearchable={true}
                        name="brand"
                        options={makeOptions}
                        onChange={this.handleMakeChange}
                        // value={makeOptions.find(
                        //   (option) => option.value === sendData.brand
                        // )}
                        value={value === undefined ? "" : value}
                        placeholder={
                          this.state.isClearEdit
                            ? ""
                            : params.id
                            ? quoteInfo.brand
                            : "Select Make"
                        }
                        required
                      />
                    </div>

                    <div>
                      <label>Car Variant</label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={false}
                        isSearchable={true}
                        name="model"
                        options={variantOptions}
                        onChange={this.handleModelChange}
                        value={valueOptions === undefined ? "" : valueOptions}
                        placeholder={
                          this.state.isClearEdit
                            ? ""
                            : params.id
                            ? quoteInfo.model
                            : "Select Variant"
                        }
                        // defaultInputValue="asas"
                        // defaultValue={this.state.sendData.year}
                        required
                      />
                    </div>

                    <div>
                      <label>Capacity</label>
                      <input
                        type="text"
                        value={
                          this.state.isClearEdit
                            ? ""
                            : this.state.isClearable
                            ? ""
                            : this.state.sendData.capacity
                        }
                        className="form-control"
                        name="capacity"
                        disabled
                      />
                    </div>

                    <div>
                      <label>Vehicle Type</label>
                      <input
                        type="text"
                        // value={
                        //   this.state.isClearEdit
                        //     ? ""
                        //     : this.state.isClearable
                        //     ? null
                        //     : this.state.isShow
                        //     ? this.state.sendData?.capacity <= 5
                        //       ? sedan
                        //       : suv
                        //     : params.id
                        //     ? this.state.sendData?.capacity <= 5
                        //       ? sedan
                        //       : suv
                        //     : null
                        // }
                        value={
                          this.state.isClearEdit
                            ? ""
                            : this.state.isClearable
                            ? ""
                            : this.state.sendData.origVehicle
                        }
                        className="form-control"
                        disabled
                      />
                    </div>

                    <div className="d-flex gap-2">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={this.state.sendData.roadAssist}
                        name="roadAssist"
                        onChange={(e) =>
                          this.setState((prevState) => ({
                            sendData: {
                              ...prevState.sendData,
                              roadAssist: e.target.checked,
                            },
                          }))
                        }
                      />
                      <label>24/7 Road Assistance</label>
                    </div>

                    <div>
                      <label>Rate</label>
                      {user.roles.some(
                        (role) => role.id === "Special Agent"
                      ) ? (
                        <select
                          required
                          value={this.state.sendData.commissionRate}
                          className="form-select"
                          name="commissionRate"
                          onChange={(e) => {
                            this.setState((prevState) => ({
                              sendData: {
                                ...prevState.sendData,
                                commissionRate: !e.target.value
                                  ? this.state.sendData.commissionRate
                                  : e.target.value,
                              },
                            }));
                          }}
                        >
                          <option disabled={this.state.sendData.commissionRate}>
                            Select rate
                          </option>
                          {this.state.sendData.commissionRate && (
                            <option>
                              {this.state.sendData.commissionRate}
                            </option>
                          )}
                          {/* {this.state.premiumRateList.map((rate) => (
                            <option key={rate}>{rate}</option>
                          ))} */}
                          {this.state.premiumRateList
                            .sort((a, b) => {
                              // Extract numeric value before " /"
                              const numA = parseFloat(a.split(" /")[0]);
                              const numB = parseFloat(b.split(" /")[0]);
                              return numB - numA; // Sort in descending order
                            })
                            .map((rate) => (
                              <option key={rate}>{rate}</option>
                            ))}
                        </select>
                      ) : (
                        <select
                          required
                          value={this.state.sendData.commissionRate}
                          className="form-select"
                          name="commissionRate"
                          onChange={(e) => {
                            this.setState((prevState) => ({
                              sendData: {
                                ...prevState.sendData,
                                commissionRate: !e.target.value
                                  ? this.state.sendData.commissionRate
                                  : e.target.value,
                              },
                            }));
                          }}
                        >
                          <option disabled={this.state.sendData.commissionRate}>
                            Select rate
                          </option>
                          {this.state.sendData.commissionRate && (
                            <option>
                              {this.state.sendData.commissionRate}
                            </option>
                          )}
                          {/* {this.state.premiumRateList.map((rate) => (
                            <option key={rate}>{rate}</option>
                          ))} */}
                          {this.state.premiumRateList
                            .sort((a, b) => {
                              // Extract numeric value before " /"
                              const numA = parseFloat(a.split(" /")[0]);
                              const numB = parseFloat(b.split(" /")[0]);
                              return numB - numA; // Sort in descending order
                            })
                            .map((rate) => (
                              <option key={rate}>{rate}</option>
                            ))}
                        </select>
                      )}
                    </div>
                  </div>
                  <div className="d-flex gap-2">
                    <p
                      className="btn fs-sm me-3 text-white"
                      style={{ backgroundColor: "#1A8326" }}
                      onClick={() => {
                        const {
                          brand,
                          commissionRate,
                          model,
                          capacity,
                          year,
                          vehicleType,
                          carBrand,
                        } = this.state.sendData;
                        if (
                          brand &&
                          commissionRate &&
                          model &&
                          capacity &&
                          year &&
                          vehicleType
                        ) {
                          console.log("cb", carBrand);
                          const saveLocal = {
                            ...this.state.sendData,
                            fvm: this.state.fvm,
                            id: this.state.id,
                            carBrand: carBrand
                              ? carBrand
                              : quoteInfo?.carCompany,
                          };
                          localStorage.setItem(
                            "quote",
                            JSON.stringify(saveLocal)
                          ); // Use JSON.stringify here

                          this.navigateTo("/quotations/computation/edit");
                          this.reload();
                        } else {
                          this.showError("Fill up all field", "Empty values");
                        }
                      }}
                    >
                      <i className="bi bi-file-earmark-check me-2"></i>COMPUTE
                    </p>

                    <p
                      type="button"
                      className="btn btn-light fs-sm"
                      onClick={() => {
                        window.location.replace("/quotations");
                      }}
                    >
                      CANCEL
                    </p>

                    {/* <div>
                      <p
                        className="btn btn-secondary shadow-lg"
                        onClick={this.resetForm.bind(this)}
                      >
                        <i class="bi bi-arrow-clockwise"></i>
                      </p>
                    </div> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(QuatationFormPage);
