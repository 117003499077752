import React from "react";
import TransactionListPresenter from "./TransactionListPresenter";
import withRouter from "../../../withRouter";
import NavBar from "../../../components/navbar";
import BaseListPage from "../../../base/BaseListPage";
import InputFactory from "../../../components/InputFactory";
import Table from "../../../components/Table";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
} from "../../../usecases/object";
import { exportCSVUseCase } from "../../../usecases/csv/usecases";
import browseFile from "../../../browseFile";
import { InfiniteScroll, Button, Progress } from "nq-component";
import DateRange from "../../../components/DateRange";
import TransactionTable from "../../../components/TransactionTable";

class TransactionListPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new TransactionListPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      deleteObjectUseCase(),
      upsertUseCase(),
      exportCSVUseCase()
    );
    this.state = {
      isOffcanvasOpen: false,
      objects: [],
      selected: [],
      currentItem: {},
    };
  }
  componentDidMount() {
    this.presenter.componentDidMount();
    this.filterList();
  }
  filterList() {
    const user = this.getCurrentUser();
    const role = this.getCurrentRoles();
    if (role.some((role) => role.id === "admin")) {
      this.presenter.filterListing({});
    } else {
      this.presenter.filterListing({});
    }
  }
  getCollectionName() {
    return "transactions";
  }
  onChangeObject(objects, index) {
    this.presenter.onChangeObject(objects, index);
  }
  onClickAdd() {
    this.presenter.onClickAdd();
  }

  onClickItem(index, field) {
    this.presenter.onClickItem(index, field);
    this.setState({ currentItem: this.getObjects()[index] });
  }

  // async download() {
  // 	// printComponent()
  // 	// const filename = 'customer.pdf';
  // 	const content = document.getElementById('containerPDF');
  // 	// this.setState({ ref_no: this.generateUniqueReferences() });

  // 	try {
  // 		content.classList.remove("d-none");

  // 		// Make the element temporarily visible
  // 		content.style.display = 'block';

  // 		const opt = {
  // 			margin: 1.5,
  // 			// filename: filename,
  // 			image: { type: 'jpeg', quality: 0.98 },
  // 			html2canvas: { scale: 2 },
  // 			jsPDF: {
  // 				unit: 'cm', format: 'letter',
  // 				orientation: 'portrait'
  // 			}
  // 		};

  // 		await html2pdf().set(opt).from(content).save();
  // 		await html2pdf().from(content).set(opt).output("blob").then((pdf) => {
  // 			pdf.name = "quotation.pdf";

  // 			saveFileUseCase().execute(pdf)
  // 				.then((res) => {
  // 					console.log(res.url)
  // 					this.setState({ quoteLINK: res.url }, async () => {
  // 						const saveThis = new UpsertUseCase();

  // 						const ref = this.generateUniqueReferences();

  // 						const saveQuotation = {
  // 							customerName: this.state.object.name,
  // 							year: this.state.quote.year,
  // 							brand: this.state.quote.brand,
  // 							model: this.state.quote.model,
  // 							variant: String(this.state.quote.variant),
  // 							roadAssist: this.state.quote.roadAssist,
  // 							commissionRate: this.state.quote.commissionRate,
  // 							commissionRate: this.state.quote.commissionRate,
  // 							emailAddress: this.state.object.email,
  // 							mobileNo: this.state.object.contact,
  // 							fileAttachment: this.state.object.file,
  // 							fileAttachment: this.state.object.file,
  // 							status: "sent",
  // 							quote_file: this.state.quoteLINK,
  // 							fvm: this.state.quote.fvm
  // 						};

  // 						const res = await saveThis.execute("quotations", saveQuotation);

  // 						const saveClientInfo = {
  // 							...this.state.object,
  // 							ref_no: ref,
  // 							quote: res.id
  // 						};

  // 						const saveTransactions = {
  // 							ref_no: ref,
  // 							quote: res.id,
  // 							customerName: this.state.object.name,
  // 							email: this.state.object.email,
  // 							contact: this.state.object.contact,
  // 							year: this.state.quote.year,
  // 							model: this.state.quote.model,
  // 							variant: String(this.state.quote.variant),
  // 							amount: this.state.quote.fvm,
  // 							docStamp: this.state.compute.docStamp,
  // 							eVAT: this.state.compute.eVAT,
  // 							lgt: this.state.compute.lgt,
  // 							roadAssist: this.state.quote.roadAssist,
  // 							commissionRate: this.state.compute.commissionRate,
  // 						};

  // 						await saveThis.execute("client_info", saveClientInfo);
  // 						this.sendEmail();
  // 						await saveThis.execute("transactions", saveTransactions);
  // 					})
  // 				})
  // 				.catch((err) => {
  // 					console.log("pdf error: " + err)
  // 				})
  // 		});

  // 		// Hide the element again
  // 		content.classList.add("d-none");
  // 		document.getElementById('customModal').style.display = 'none';
  // 	} catch (error) {
  // 		console.error('Error:', error.message);
  // 	}
  // }

  onClickImport() {
    browseFile("*").then((files) => {
      if (files.length > 0) {
        const file = files[0];
        this.presenter.onClickImport(file);
      }
    });
  }
  onCollapse(index, object) {
    const user = this.getCurrentUser();
    const role = this.getCurrentRoles();
    console.log("obb", object);
    return (
      <div>
        <div className="d-flex">
          <ul className="list-unstyled ms-1 text-truncate">
            <li>
              <span className="ms-2 fw-light fw-bold">Email Address: </span>
              <span className="text-nowrap">{object.email}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Contact: </span>
              <span className="text-nowrap">+63{object.contact}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Capacity: </span>
              <span className="text-nowrap">{object.capacity}</span>
            </li>
            <br />

            <li>
              <span className="ms-2 fw-light fw-bold">
                Basic Premium with AON:
              </span>

              <span className="text-nowrap">
                {object?.basicwithAON && object?.pRateAON !== "0"
                  ? (
                      parseFloat(
                        object.basicwithAON.replace(/[^0-9.-]+/g, "")
                      ) + parseFloat(object.auto || "0")
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "0.00"}
              </span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">
                Doc Stamps with AON:{" "}
              </span>
              <span className="text-nowrap">
                {object?.docStamp && object?.pRateAON !== "0"
                  ? parseFloat(
                      object.docStamp.replace(/[^0-9.-]+/g, "")
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "0.00"}
              </span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">E-VAT with AON: </span>
              <span className="text-nowrap">
                {object?.eVAT && object?.pRateAON !== "0"
                  ? parseFloat(
                      object.eVAT.replace(/[^0-9.-]+/g, "")
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "0.00"}
              </span>
            </li>

            <li>
              <span className="ms-2 fw-light fw-bold">LGT with AON: </span>
              <span className="text-nowrap">
                {object?.lgt && object?.pRateAON !== "0"
                  ? parseFloat(
                      object.lgt.replace(/[^0-9.-]+/g, "")
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "0.00"}
              </span>
            </li>
            <br />
            <li>
              <span className="ms-2 fw-light fw-bold">
                Basic Premium without AON:
              </span>

              <span className="text-nowrap">
                {object?.basicwithoutAON && object?.pRate !== "0"
                  ? (
                      parseFloat(
                        object.basicwithoutAON.replace(/[^0-9.-]+/g, "")
                      ) + parseFloat(object.auto || "0")
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "0.00"}
              </span>
            </li>

            <li>
              <span className="ms-2 fw-light fw-bold">
                Doc Stamps without AON:{" "}
              </span>
              <span className="text-nowrap">
                {object?.docStamp2 && object?.pRate !== "0"
                  ? parseFloat(
                      object.docStamp2.replace(/[^0-9.-]+/g, "")
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "0.00"}
              </span>
            </li>

            <li>
              <span className="ms-2 fw-light fw-bold">E-VAT without AON: </span>
              <span className="text-nowrap">
                {object?.eVAT2 && object?.pRate !== "0"
                  ? parseFloat(
                      object.eVAT2.replace(/[^0-9.-]+/g, "")
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "0.00"}
              </span>
            </li>

            <li>
              <span className="ms-2 fw-light fw-bold">LGT without AON: </span>
              <span className="text-nowrap">
                {object?.lgt2 && object?.pRate !== "0"
                  ? parseFloat(
                      object.lgt2.replace(/[^0-9.-]+/g, "")
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "0.00"}
              </span>
            </li>
            <br />
            <li>
              <span className="ms-2 fw-light fw-bold">Road Assistance: </span>
              <span className="text-nowrap">
                {object?.roadAssistAmount
                  ? parseFloat(
                      object.roadAssistAmount.replace(/[^0-9.-]+/g, "")
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "0.00"}
              </span>
            </li>

            <li>
              <span className="ms-2 fw-light fw-bold">Rate: </span>
              <span className="text-nowrap">
                {object.rateQuote} / {object.rateSelect}%
              </span>
            </li>
            {/* <li>
              <span className="ms-2 fw-light fw-bold">File Attachment: </span>
              <span className="text-nowrap">
                {object?.fileAttachment ? (
                  <a
                    href={object.fileAttachment}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="bi bi-paperclip" style={{ color: "#014900" }}>
                      link
                    </i>{" "}
                    
                  </a>
                ) : (
                  "No file attachment"
                )}
              </span>
            </li> */}
            <li>
              <span className="ms-2 fw-light fw-bold">File Attachments: </span>
              <span className="text-nowrap">
                {object?.fileAttachment && object.fileAttachment.length > 0
                  ? object.fileAttachment.map((file, index) => (
                      <a
                        key={index}
                        href={file} // Assuming each element in the array is a URL
                        target="_blank"
                        rel="noopener noreferrer"
                        className="me-2" // Add margin to space out links
                      >
                        <i
                          className="bi bi-paperclip"
                          style={{ color: "#014900", marginRight: "5px" }}
                        ></i>
                        {`Link ${index + 1}`}{" "}
                        {/* Display the link with index */}
                      </a>
                    ))
                  : "No file attachments"}
              </span>
            </li>

            {/* <li>
              <span className="ms-2 fw-light fw-bold">File Attachment: </span>
              <span className="text-nowrap">
                {object?.fileAttachment ?? "No file attachment"}
              </span>
            </li> */}
            {/* <li>
              <span className="ms-2 fw-light fw-bold">Year: </span>
              <span className="text-nowrap">{object.year}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Model: </span>
              <span className="text-nowrap">{object.model}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Car Variant</span>
              <span className="text-nowrap">{object.carVar}</span>
            </li>

            <li>
              <span className="ms-2 fw-light fw-bold">Amount</span>
              <span className="text-nowrap">{object.amount}</span>
            </li> */}
          </ul>
        </div>
        {/* {user.roles.some(
          (role) => role.name === "Special Agent" || role.name === "Admin"
        ) && ( */}
        {/* <button
          onClick={this.onClickItem.bind(this, index)}
          className="btn btn-primary"
          style={{ backgroundColor: "#014900", border: 0 }}
        >
          EDIT
        </button> */}
        <button
          onClick={this.onClickItem.bind(this, index)}
          className="btn btn-primary"
          style={{ backgroundColor: "#014900", border: 0 }}
        >
          DOWNLOAD
        </button>
        {/* )} */}
      </div>
    );
  }
  toggleOffcanvas = () => {
    this.setState((prevState) => ({
      isOffcanvasOpen: !prevState.isOffcanvasOpen,
    }));
  };

  onChangeDate(where) {
    console.log("where date", where);
    this.presenter.onChangeDate(where);
  }

  render() {
    const { isOffcanvasOpen } = this.state;
    const schema = this.getSchema(this.getCollectionName());
    const { objects, selected, total, count, progress } = this.state;
    console.log("trans", objects);
    const user = this.getCurrentUser();
    const role = this.getCurrentRoles();
    const dDate = new Date().toDateString().split(" ").slice(1).join(" ");
    const excludeFields =
      user.roles[0].id === "Standard Agent" ||
      user.roles[0].id === "Special Agent"
        ? [
            "acl",
            "password",
            "br",
            "or",
            "items",
            "id",
            // "createdAt",
            "branchName",
            "refNo",
            "emailAddress",
            "mobileNo",
            "year",
            "carVar",
            "capacity",
            "docStamp",
            "expandVat",
            "lgt",
            "roadAssist",
            "commissionRate",
            "quote",
            "model",
            "eVAT",
            "make",
            "carCompany",
            "premiumWithoutAON",
            "premiumWithAON",
            "basicwithoutAON",
            "basicwithAON",
            "amount",
            "agent",
            "auto",
            "road",
            "pRate",
            "pRateAON",
            "docStamp2",
            "eVAT2",
            "lgt2",
          ]
        : [
            "acl",
            "password",
            "br",
            "or",
            "items",
            "id",
            // "createdAt",
            "branchName",
            "refNo",
            "emailAddress",
            "mobileNo",
            "year",
            "carVar",
            "capacity",
            "docStamp",
            "expandVat",
            "lgt",
            "roadAssist",
            "commissionRate",
            "quote",
            "model",
            "eVAT",
            "make",
            "carCompany",
            "premiumWithoutAON",
            "premiumWithAON",
            "basicwithoutAON",
            "basicwithAON",
            "amount",
            "auto",
            "road",
            "docStamp2",
            "eVAT2",
            "lgt2",
            "pRate",
            "pRateAON",
          ];

    if (!schema) return <Progress />;
    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-bold mt-3 text-capitalize">Transactions</h1>
                {selected.length > 0 ? (
                  <div>
                    <span className="ms-2">Selected: </span>
                    <span className="fs-sm text-nowrap">{selected.length}</span>
                    <span className="ms-1">of </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                ) : (
                  <div>
                    <span className="ms-2">Total: </span>
                    <span className="fs-sm text-nowrap">{objects.length}</span>
                    <span className="ms-1">of </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                )}

                <div className="d-block d-md-none mt-2">
                  <div className="d-flex align-items-center ">
                    <i
                      className="bi bi-filter"
                      style={{ fontSize: "25px", color: "#014900" }}
                      onClick={this.toggleOffcanvas}
                      aria-controls="offcanvasRight"
                    ></i>
                  </div>

                  <div
                    className={`offcanvas offcanvas-end ${
                      isOffcanvasOpen ? "show" : ""
                    }`}
                    tabIndex="-1"
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                    style={{
                      visibility: isOffcanvasOpen ? "visible" : "hidden",
                    }}
                  >
                    <div
                      className="offcanvas-header"
                      style={{ backgroundColor: "#014900" }}
                    >
                      <h5
                        className="offcanvas-title text-white"
                        id="offcanvasRightLabel"
                      >
                        Filters
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={this.toggleOffcanvas}
                      ></button>
                    </div>
                    <div className="offcanvas-body">
                      <div className=" mt-3">
                        {Object.keys(schema.filters || {}).map((field) => {
                          let { type, ...options } = schema.filters[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        <DateRange
                          onChange={this.onChangeDate.bind(this)}
                          // field="date"
                          field="createdAt"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-md-flex d-none mt-3 justify-content-between align-items-center ">
                <div className="d-flex">
                  {Object.keys(schema.filters || {}).map((field) => {
                    let { type, ...options } = schema.filters[field];

                    return (
                      <>
                        <InputFactory
                          key={field}
                          className="ms-1 me-2"
                          type={type}
                          field={field}
                          where={{}}
                          onChange={this.onChangeFilter.bind(this, type)}
                          excludeFields={["agent"]}
                          {...options}
                        />
                      </>
                    );
                  })}
                  {user.roles[0].id === "Admin" && (
                    <InputFactory
                      // key={field}
                      className="ms-1 me-2"
                      type="String"
                      field="agent"
                      placeholder="Agent Name"
                      where={{}}
                      onChange={this.onChangeFilter.bind(this, "String")}
                      // excludeFields={["agent"]}
                      // {...options}
                    />
                  )}

                  <DateRange
                    onChange={this.onChangeDate.bind(this)}
                    // field="date"
                    field="createdAt"
                  />
                </div>

                {/* {this.state.selected.length > 0 && (
                  <button
                    className="btn btn-danger"
                    aria-expanded="false"
                    onClick={this.onClickDeleteSelected.bind(this)}
                  >
                    <i className="bi bi-trash"></i>Delete
                  </button>
                )} */}
              </div>

              <TransactionTable
                fields={schema.fields}
                groups={schema.groups}
                objects={objects}
                collapsable
                // selectable
                excludeFields={Object.keys(schema.fields).reduce((acc, key) => {
                  const options = schema.fields[key];
                  if (options.read === false) {
                    acc.push(key);
                  }
                  switch (options._type || options.type) {
                    case "Relation":
                    case "Array":
                    case "Object":
                    case "File":
                      acc.push(key);
                      break;
                    default:
                  }
                  return acc;
                }, excludeFields)}
                // selected={selected}
                // onSelect={this.onSelect.bind(this)}
                // onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                // onClickItem={this.onClickItem.bind(this)}
                onCollapse={this.onCollapse.bind(this)}
                className="mt-3"
              />
            </div>
            {/* <div className="position-fixed bottom-0 end-0 m-4">
              <Button
                className="btn shadow-sm"
                onClick={this.onClickAdd.bind(this)}
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "25px",
                  backgroundColor: "#014900",
                }}
              >
                <i className="bi bi-plus text-white" />
              </Button>
            </div> */}
          </InfiniteScroll>
        </div>

        {/* <div id='containerPDF' ref={this.quotePDF} className='d-none' style={{ position: "relative", minHeight: "920px" }}>
          <img src="/mercantile-logo.png" width={220} />

          <div style={{ marginLeft: '370px', top: "2px", position: "absolute" }}>
            <p style={{ fontSize: ".5rem", fontWeight: "bold", lineHeight: ".4" }}>Mercantile Insurance Building, General Luna St. corner Beaterio St.</p>
            <p style={{ fontSize: ".5rem", fontWeight: "bold", lineHeight: ".4" }}>Intramuros, Manila, Philippines</p>
            <p style={{ fontSize: ".5rem", fontWeight: "bold", lineHeight: ".4" }}>(632) 8527-7701 to 20 • gen_info@mic.com.ph • www.mercantile.ph</p>
          </div>

          <div style={{ marginLeft: '400px', top: "80px", position: "absolute" }}>
            <p style={{ fontSize: ".7rem", fontWeight: "bold", lineHeight: ".4" }}>MOTORCAR INSURANCE PROPOSAL</p>
            <p style={{ fontSize: ".7rem", fontWeight: "bold", lineHeight: ".4" }}>{dDate}</p>
          </div>


          <div style={{ position: "absolute", top: "130px", maxWidth: "620px" }}>
            <p style={{ fontSize: ".8rem" }} > Thank you for giving THE MERCANTILE INSURANCE CO., INC., an opportunity to provide you with a proposal
              for your car insurance requirements.</p>
          </div>

          <div style={{ position: "absolute", top: "180px", maxWidth: "620px" }}>
            <p style={{ fontSize: ".8rem" }}>Below are the pertinent information you will need in evaluating our quotation</p>
          </div>

          <div style={{ position: "absolute", top: "220px", maxWidth: "620px" }}>
            <p style={{ fontSize: ".8rem", lineHeight: ".4" }}>ASSURED &#160; &#160; &#160; &#160; &#160; &#160; &#160; &#160; &#160; &#160; <span style={{ fontSize: ".8rem", fontWeight: "bold" }}>{name}</span></p>
            <p style={{ fontSize: ".8rem", lineHeight: ".4" }}>REFERENCE NO &#160; &#160; &#160; &#160; <span style={{ fontSize: ".8rem", fontWeight: "bold" }}>{this.state.currentItem.ref_no}</span></p>
          </div>

          <div style={{ position: "absolute", top: "270px", maxWidth: "620px" }}>
            <p style={{ fontSize: ".8rem", lineHeight: ".6" }}>DESCRIPTIONS  &#160; &#160; &#160; &#160; &#160; <span style={{ fontSize: ".8rem", fontWeight: "bold", lineHeight: "1" }}>{this.state.currentItem.ref_no}</span></p>
          </div>

          <table style={{ position: "absolute", top: "300px", }}>
            <tr className='head' style={{ backgroundColor: "#1A8326" }} >
              <th style={{ color: "white", fontSize: ".8rem", textAlign: "left" }}>COVERAGE</th>
              <th style={{ color: "white", fontSize: ".8rem", textAlign: "center" }}>Sum Insured</th>
              <th style={{ color: "white", fontSize: ".8rem", textAlign: "center", wordWrap: "break-word" }}>Comprehensive Without<br /> AON</th>
              <th style={{ color: "white", fontSize: ".8rem", textAlign: "center", wordWrap: "break-word" }}>Comprehensive With <br /> AON</th>
            </tr>

            {
              data.map((row, index) => (
                <tr key={index}>
                  <td style={{ textAlign: "left" }}>{row.coverage}</td>
                  <td style={{ textAlign: row.coverage != "" ? "right" : "left" }}>{index == 0 ? <span style={{ paddingRight: "8px" }}>&#8369;</span> : ""}{row.sumInsured}</td>
                  <td style={{ textAlign: "right" }}>{row.sumInsured == "Basic Premium" ? <span style={{ paddingRight: "8px" }}>&#8369;</span> : ""}{row.amount_wo_aon}</td>
                  <td style={{ textAlign: "right" }}>{row.amount_w_aon}</td>
                </tr>
              ))
            }

            <tr style={{ backgroundColor: "#1A8326" }}>
              <td ></td>
              <td style={{ textAlign: "center", color: "white", fontWeight: "bold", fontSize: "12px" }}>TOTAL GROSS PREMIUM</td>
              <td style={{ textAlign: "right", color: "white" }}><span style={{ paddingRight: "8px" }}>&#8369;</span>{totalGrossPrem2}</td>
              <td style={{ textAlign: "right", color: "white" }}>{totalGrossPrem1}</td>
            </tr>
          </table>

          <div style={{ position: "absolute", top: "680px", maxWidth: "620px" }}>
            <p style={{ fontSize: ".8rem", lineHeight: .7 }}>AGENTS CODE:  &#160;  &#160;  &#160;   &#160;  &#160;  &#160; &#160; &#160; &#160; &#160; &#160; &#160; &#160; &#160; &#160; &#160; &#160; <span style={{ fontSize: ".8rem", fontWeight: "bold" }}>{user.agentCode}</span></p>
            <p style={{ fontSize: ".8rem", lineHeight: .7 }}>DEDUCTIBLE/PARTICIPATION:  &#160; &#160; &#160; &#160; &#160; <span style={{ fontSize: ".8rem", fontWeight: "bold", lineHeight: "1" }}>0.50% of Sum Insured minimum of Php 2,000.00 whichever is higher</span></p>
          </div>

          <div style={{ position: "absolute", top: "740px", maxWidth: "620px" }}>
            <p style={{ fontSize: ".8rem", lineHeight: 1 }}>CONDITIONS:</p>
          </div>

          <p style={{ position: "absolute", top: "740px", left: "40px", lineHeight: .7, fontSize: ".8rem", marginLeft: "105px", fontWeight: "bold" }}>Subject to the standard insuring provisions of the Mercantile Insurance Co.,</p>
          <p style={{ position: "absolute", top: "760px", left: "40px", lineHeight: .7, fontSize: ".8rem", marginLeft: "105px", fontWeight: "bold" }}>Inc.'s Motorcar Insurance Policy including the usual warranties, clauses and</p>
          <p style={{ position: "absolute", top: "780px", left: "40px", lineHeight: .7, fontSize: ".8rem", marginLeft: "105px", fontWeight: "bold" }}>other conditions applicable</p>

          <div style={{ position: "absolute", top: "790px", maxWidth: "620px" }}>
            <p style={{ fontSize: ".7rem", lineHeight: ".7" }}>Note:</p>
            <p style={{ fontSize: ".7rem", lineHeight: ".7" }}>{dDate}</p>
          </div>

          <div style={{ position: "absolute", top: "840px", maxWidth: "620px" }}>
            <p style={{ fontSize: ".7rem", lineHeight: ".7" }}>Should you have any further inquiries regarding our proposal, please do not hesitate to inform us immediately.
            </p>
            <p style={{ fontSize: ".7rem", lineHeight: ".7" }}>We are looking forward to receiving your favorable response.
            </p>
            <p style={{ fontSize: ".7rem", lineHeight: ".7" }}>Thank you.
            </p>
          </div>

          <div style={{ position: "absolute", top: "900px", maxWidth: "620px" }}>
            <p style={{ fontSize: ".8rem", lineHeight: 1, fontWeight: "bold" }}>THE MERCANTILE INSURANCE</p>
          </div>
        </div > */}
      </>
    );
  }
}

export default withRouter(TransactionListPage);
