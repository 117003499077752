import React from "react";
import UserFormPresenter from "./UserFormPresenter";
import withRouter from "../../../withRouter";
import NavBar from "../../../components/navbar";
import BaseFormPage from "../../../base/BaseFormPage";
import InputFactory from "../../../components/InputFactory";
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../../usecases/object";
import { restUseCase } from "../../../usecases/rest";

class UserFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {},
      advanced: false,
      filtered: [],
      roleAccess: "",
    };
    this.presenter = new UserFormPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      restUseCase(),
      findObjectUseCase()
    );
  }

  getCollectionName() {
    return "users";
  }

  onClickAdvance() {
    this.setState({ advanced: !this.state.advanced });
  }

  onChangeObject(object) {
    this.presenter.onChangeObject(object);
  }

  onChangeFilter(value) {
    this.presenter.onChangeFilter(value);
  }

  onChange(value, field) {
    console.log("val", field);
    if (field === "roles") {
      this.setState({ roleAccess: value[0]?.name });
    }
    this.presenter.onChange(value, field);
  }

  getAcl() {
    const user = this.getCurrentUser();
    return {
      // read: ["*", user.id, "role:admin", "role:Admin"],
      write: [
        "*",
        // user.id,
        // "role:admin",
        // "role:Admin",
        // "role:Special Agent",
        // "role:Standard Agent",
      ],
    };
  }

  getCurrentDateFormatted() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  isSpecialAgentSelected() {
    const { object } = this.state;
    return (
      object.roles && object.roles.some((role) => role.id === "Special Agent")
    );
  }

  render() {
    const { object } = this.state;
    console.log("obb", object);
    const roleNames = object?.roles?.map((role) => role.id) || [];
    console.log("Role Names:", roleNames);
    const role = this.getCurrentRoles();
    console.log(role);
    const user = this.getCurrentUser();
    const schema = this.getSchema(this.getCollectionName());
    if (!schema) return <h1>no schema</h1>;
    const label = this.getObjectId() === undefined ? "Add New " : "Edit ";
    const currentDate = this.getCurrentDateFormatted();
    const masterIdentifier = role[0].id === "masterAdmin";
    console.log("ROLE ACCESS", this.state?.roleAccess);
    return (
      <>
        <NavBar
          action={() => (
            <div className="dropdown dropstart d-inline-block">
              <i
                role="button"
                data-bs-toggle="dropdown"
                className="bi bi-three-dots-vertical"
              ></i>
              <div className="dropdown-menu fs-xs">
                <button
                  onClick={this.onClickAdvance.bind(this)}
                  className="dropdown-item py-3"
                >
                  <i className="bi bi-gear pe-2" />
                  Toggle Advance
                </button>
              </div>
            </div>
          )}
        />
        <div className="overflow-auto">
          <div className="h-100">
            <div className="p-3 p-lg-4">
              <h1 className="fw-bold mt-3 text-capitalize">{label} User</h1>

              <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                <form onSubmit={this.onSubmitForm.bind(this)}>
                  <div className="row g-3 py-4">
                    {/* <div className="mb-3">
                      <label className="form-label">Username</label>
                      <InputFactory
                        type="String"
                        field="username"
                        object={object}
                        placeholder="Enter Username"
                        onChange={this.onChange.bind(this)}
                        required={true}
                      />
                    </div> */}
                    <div className="mb-3">
                      <label className="form-label">Roles</label>
                      <InputFactory
                        type="Relation"
                        // _type="Pointer"
                        label="Role"
                        target="roles"
                        field="roles"
                        object={object}
                        placeholder="Select Role"
                        onChange={this.onChange.bind(this)}
                        required={true}
                        dynamic={false}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Email Address</label>
                      <InputFactory
                        type="String"
                        field="email"
                        object={object}
                        placeholder="Enter Email Address"
                        onChange={this.onChange.bind(this)}
                        required={true}
                        unique={true}
                      />
                    </div>
                    {/* {(this.state?.roleAccess !== "Admin" &&
                      this.state?.roleAccess !== "") ||
                      (roleNames[0] !== "Admin" ? (
                        <div className="mb-3">
                          <label className="form-label">Agent Code</label>
                          <InputFactory
                            type="String"
                            field="agentCode"
                            object={object}
                            placeholder="Enter Agent Code"
                            onChange={this.onChange.bind(this)}
                            required={this.state?.roleAccess !== "Admin"}
                          />
                        </div>
                      ) : null)} */}
                    {(this.state?.roleAccess !== "Admin" &&
                      this.state?.roleAccess) ||
                    (this.state?.roleAccess === "" &&
                      roleNames[0] !== "Admin") ? (
                      <div className="mb-3">
                        <label className="form-label">Agent Code</label>
                        <InputFactory
                          type="String"
                          field="agentCode"
                          object={object}
                          placeholder="Enter Agent Code"
                          onChange={this.onChange.bind(this)}
                          required={this.state?.roleAccess !== "Admin"}
                        />
                      </div>
                    ) : null}

                    {/* {(this.state?.roleAccess !== "Admin" &&
                      this.state?.roleAccess) ||
                    (this.state?.roleAccess === "" &&
                      roleNames[0] !== "Admin") ? (
                      <div className="mb-3">
                        <label className="form-label">
                          Agent Name / Admin Name
                        </label>
                        <InputFactory
                          type="String"
                          field="agentName"
                          object={object}
                          placeholder="Enter Agent Name"
                          onChange={this.onChange.bind(this)}
                          required={this.state?.roleAccess !== "Admin"}
                        />
                      </div>
                    ) : null} */}
                    <div className="mb-3">
                      <label className="form-label">
                        Agent Name / Admin Name
                      </label>
                      <InputFactory
                        type="String"
                        field="agentName"
                        object={object}
                        placeholder="Enter Agent Name"
                        onChange={this.onChange.bind(this)}
                        required={this.state?.roleAccess !== "Admin"}
                      />
                    </div>

                    {(this.state?.roleAccess !== "Admin" &&
                      this.state?.roleAccess) ||
                    (this.state?.roleAccess === "" &&
                      roleNames[0] !== "Admin") ? (
                      <div className="mb-3">
                        <label className="form-label">
                          Account Officer Email
                        </label>
                        <InputFactory
                          type="String"
                          field="accountOfficerEmail"
                          object={object}
                          placeholder="Enter Officer Email"
                          onChange={this.onChange.bind(this)}
                          required={this.state?.roleAccess !== "Admin"}
                        />
                      </div>
                    ) : null}

                    {(this.state?.roleAccess !== "Admin" &&
                      this.state?.roleAccess) ||
                    (this.state?.roleAccess === "" &&
                      roleNames[0] !== "Admin") ? (
                      <div className="mb-3">
                        <label className="form-label">Free Passenger</label>
                        <InputFactory
                          type="String"
                          field="freePassenger"
                          object={object}
                          placeholder="Enter Free Passenger"
                          onChange={this.onChange.bind(this)}
                          required={this.state?.roleAccess !== "Admin"}
                        />
                      </div>
                    ) : null}

                    {this.isSpecialAgentSelected() && (
                      <div className="mb-3">
                        <label className="form-label">Group</label>
                        <InputFactory
                          type="PointerGroup"
                          target="groups"
                          field="group"
                          object={object}
                          placeholder="Select Group"
                          onChange={this.onChange.bind(this)}
                          required={false}
                          dynamic={false}
                        />
                      </div>
                    )}
                    <div className="mb-3">
                      <label className="form-label">Password</label>
                      <br />
                      <InputFactory
                        type="String"
                        _type="Password"
                        field="password"
                        object={object}
                        placeholder="Enter Password"
                        minlength="8"
                        onChange={this.onChange.bind(this)}
                        required={object.id ? false : true}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Confirm Password</label>
                      <InputFactory
                        type="String"
                        _type="Password"
                        field="confirmPassword"
                        object={object}
                        placeholder="Confirm Password"
                        onChange={this.onChange.bind(this)}
                        required={object.id ? false : true}
                      />
                    </div>
                    {(this.state?.roleAccess !== "Admin" &&
                      this.state?.roleAccess) ||
                    (this.state?.roleAccess === "" &&
                      roleNames[0] !== "Admin") ? (
                      <div className="mb-3">
                        <label className="form-label">Branch</label>
                        <InputFactory
                          type="PointerBranch"
                          target="branches"
                          field="branchName"
                          object={object}
                          placeholder="Select Branch"
                          onChange={this.onChange.bind(this)}
                          required={this.state?.roleAccess !== "Admin"}
                        />
                      </div>
                    ) : null}

                    {/* <div className="mb-3">
                      <label className="form-label">Status</label>
                      <InputFactory
                        type="String"
                        _type="Enum"
                        options={["ACTIVE", "INACTIVE"]}
                        field="status"
                        object={object}
                        placeholder="Select Status"
                        onChange={this.onChange.bind(this)}
                        required={true}
                      />
                    </div> */}
                  </div>
                  <div className="d-flex justify-content-between ">
                    <button
                      type="submit"
                      className="btn fs-sm me-3 text-white"
                      style={{ backgroundColor: "#1A8326" }}
                    >
                      <i className="bi bi-file-earmark-check me-2"></i>SAVE
                    </button>
                    <button
                      type="button"
                      className="btn btn-light fs-sm"
                      onClick={() => this.navigateTo("/users")}
                    >
                      CANCEL
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(UserFormPage);
