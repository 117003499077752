import { Progress } from "nq-component";
import BaseFormPresenter from "../../base/BaseFormPresenter";

class AccountPresenter extends BaseFormPresenter {
  constructor(view, getObjectUseCase, upsertUseCase, signInUseCase) {
    super(view, getObjectUseCase, upsertUseCase);
    this.signInUseCase = signInUseCase;
    this.password = {};
  }

  onChangePassword(value, field) {
    this.password[field] = value;
  }

  async onChangePicture(value) {
    this.change["picture"] = value;
    await this.submit();
    this.view.reload();
  }

  async save() {
    const collection = this.view.getCollectionName();
    if (this.object.id) {
      this.change.id = this.object.id;
      this.change.acl = this.view.getAcl();
    } else {
      this.change.acl = this.view.getAcl();
    }
    try {
      await this.upsertUseCase.execute(collection, this.change);
      this.change = {};
    } catch (error) {
      throw new Error("This email already exists.");
    }
  }

  async submit() {
    if (Object.values(this.change).length === 0) {
      this.view.showSuccessSnackbar("No changes to save.");
      return;
    }
    try {
      this.view.submitting();
      await this.save();
      this.view.submissionSuccess();
      this.view.showSuccessSnackbar("Successfully saved!");
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);

      // Automatically refresh the page when this specific error occurs
      if (error.message === "This email already exists.") {
        <Progress />;
        setTimeout(() => {
          window.location.reload();
        }, 4000); // Refresh after 2 seconds to ensure user sees the error
      }
    }
  }

  // async save() {
  //   const collection = this.view.getCollectionName();
  //   if (this.object.id) {
  //     this.change.id = this.object.id;
  //     this.change.acl = this.view.getAcl();
  //   } else {
  //     this.change.acl = this.view.getAcl();
  //   }
  //   try {
  //     await this.upsertUseCase.execute(collection, this.change);
  //     this.change = {};
  //   } catch (error) {
  //     throw new Error("This email already exists.");
  //   }
  // }

  // async submit() {
  //   if (Object.values(this.change).length === 0) {
  //     this.view.showSuccessSnackbar("No changes to save.");
  //     return;
  //   }
  //   try {
  //     this.view.submitting();
  //     await this.save();
  //     this.view.submissionSuccess();
  //     this.view.showSuccessSnackbar("Successfully saved!");
  //   } catch (error) {
  //     this.view.submissionError(error);
  //     this.view.showError(error);

  //     // Check if the error message matches and refresh the page upon confirmation
  //     if (error.message === "This email already exists.") {
  //       window.location.reload();
  //     }
  //   }
  // }

  // async submit() {
  //   if (Object.values(this.change).length === 0) {
  //     this.view.showSuccessSnackbar("No changes to save.");
  //     return;
  //   }
  //   try {
  //     this.view.submitting();
  //     await this.save();
  //     this.view.submissionSuccess();
  //     this.view.showSuccessSnackbar("Successfully saved!");
  //   } catch (error) {
  //     this.view.submissionError(error);
  //     this.view.showError(error);
  //   }
  // }

  async onSubmitPassword() {
    const { oldPassword, newPassword, confirmNewPassword } = this.password;
    if (newPassword !== confirmNewPassword) {
      this.view.showError("Password must be the same");
      return;
    }
    if (
      newPassword.includes(oldPassword) ||
      confirmNewPassword.includes(oldPassword)
    ) {
      this.view.showError("New password must not include the old password");
      return;
    }
    try {
      const user = this.object;
      console.log("user send", user);
      console.log("old pass", oldPassword);
      const access = {
        username: user.email,
        password: oldPassword,
        masterKey: "97e3fa4c-cbcb-4104-b6d0-52ee25aa2245",
      };

      const response = await this.signInUseCase.execute(access, {
        token: false,
      });
      console.log("response", response);
      this.change["password"] = this.password["newPassword"];
      await this.submit();
    } catch (error) {
      if (error.code === 404) {
        this.view.showError("Invalid old password");
        return;
      }
      this.view.showError(
        "The email address you entered is already in use. Please use a different email address."
      );
    }
  }
}

export default AccountPresenter;
