import React from "react";
import classNames from "../../classNames";
import { findObjectUseCase } from "../../usecases/object";

const noop = () => {};
const defaultProps = {
  loadOptions: noop,
  onChange: noop,
  onSearch: noop,
  onClickAdd: noop,
  onClickClear: noop,
  onFocus: noop,
  value: { label: "", value: "" },
  placeholder: "Select",
  dynamic: false,
};

// // function SelectSearch({
// //   className,
// //   value,
// //   placeholder,
// //   onChange,
// //   onSearch,
// //   onClickAdd,
// //   onClickClear,
// //   onFocus,
// //   focus,
// //   options,
// //   required,
// //   dynamic,
// //   uppercase,
// //   dis,
// // }) {
// //   const [search, setSearch] = React.useState("");
// //   const [text, setText] = React.useState("");
// //   const [isOpen, setOpen] = React.useState(false);
// //   const ref = React.useRef(null);

// //   // handle click outside event
// //   const handleClickOutside = (event) => {
// //     if (ref.current && !ref.current.contains(event.target)) {
// //       setOpen(false);
// //       onFocus(false);
// //     }
// //   };

// //   React.useEffect(() => {
// //     // add when mounted
// //     document.addEventListener("mousedown", handleClickOutside);
// //     // return function to be called when unmounted
// //     return () => {
// //       document.removeEventListener("mousedown", handleClickOutside);
// //     };
// //   }, []);
// //   // when value change
// //   React.useEffect(() => {
// //     setText(value.label);
// //     setSearch("");
// //   }, [value]);

// //   function _onChange(e) {
// //     const value = e.target.value;
// //     // Get the new character input by the user by removing the previous text from the current value.
// //     const character = value.replace(text, "");
// //     if (search === "") {
// //       e.target.value = character;
// //     }
// //     if (uppercase) {
// //       e.target.value = value.toUpperCase();
// //     }
// //     // set the new character input by the user
// //     setSearch(e.target.value);
// //     setText(e.target.value);

// //     onSearch(e.target.value);
// //   }

// //   function onClick(e) {
// //     // e.target.setSelectionRange(0, 0);
// //     if (!isOpen) {
// //       e.target.blur();
// //     }
// //     setOpen(!isOpen || focus);
// //   }

// //   function onSelect(value, index) {
// //     onChange(value, index);
// //     setOpen(false);
// //     if (search) {
// //       // reset the options if has search
// //       onSearch("");
// //     }
// //   }

// //   function _onFocus(e) {
// //     if (!focus) {
// //       e.target.blur();
// //     }
// //     onFocus(true);
// //   }

// //   function onClickIcon(e) {
// //     console.log("clicked");
// //     if (text) {
// //       onClickClear(e);
// //     } else if (dynamic) {
// //       onClickAdd(e);
// //     } else {
// //       onClick(e);
// //     }
// //   }

// //   const style = { cursor: isOpen && focus ? "text" : "default" };
// //   const icon = text ? "bi bi-x" : dynamic ? "bi bi-plus" : "bi bi-chevron-down";
// //   return (
// //     <div
// //       ref={ref}
// //       style={{
// //         position: "relative",
// //       }}
// //     >
// //       <div className="input-group">
// //         <input
// //           onClick={onClick}
// //           type="text"
// //           className={classNames(className, "form-control border-end-0 pe-0")}
// //           placeholder={placeholder}
// //           value={text}
// //           onChange={_onChange}
// //           onFocus={_onFocus}
// //           style={style}
// //           required={required}
// //           disabled={dis}
// //         />
// //         <button
// //           onClick={onClickIcon}
// //           className="btn btn-link"
// //           type="button"
// //           style={{ display: dis && `none` }}
// //         >
// //           <i className={icon}></i>
// //         </button>
// //       </div>

// //       {isOpen && (
// //         <ul
// //           className="list-group rounded bg-white"
// //           style={{
// //             position: "absolute",
// //             top: "2.8rem",
// //             width: "100%",
// //             maxHeight: "20rem",
// //             overflowY: "auto",
// //             zIndex: 1000,
// //             boxShadow: "1px 5px 15px rgba(0, 0, 0, .08)",
// //             border: "1px solid rgba(0, 0, 0, .06)",
// //             scrollbarWidth: "thin",
// //           }}
// //         >
// //           {options
// //             .map((option, index) => (
// //               <li
// //                 key={option.value}
// //                 type="button"
// //                 className="list-group-item list-group-item-action"
// //                 onClick={() => onSelect(option, index)}
// //               >
// //                 {option.label}
// //               </li>
// //             ))
// //             .sort()}
// //         </ul>
// //       )}
// //     </div>
// //   );
// // }

// // SelectSearch.defaultProps = defaultProps;
// // export default SelectSearch;
// function SelectSearchGroup({
//   className,
//   value,
//   placeholder,
//   onChange,
//   onSearch,
//   onClickAdd,
//   onClickClear,
//   onFocus,
//   focus,
//   options,
//   required,
//   dynamic,
//   uppercase,
//   dis,
//   schemas,
// }) {
//   const [search, setSearch] = React.useState("");
//   const [text, setText] = React.useState("");
//   const [op, setOp] = React.useState("");
//   const [isOpen, setOpen] = React.useState(false);
//   const ref = React.useRef(null);
//   console.log("hehe", schemas);
//   const handleClickOutside = (event) => {
//     if (ref.current && !ref.current.contains(event.target)) {
//       setOpen(false);
//       onFocus(false);
//     }
//   };

//   React.useEffect(() => {
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   React.useEffect(() => {
//     setText(value.label); // Ensure text reflects the selected branch's name
//     setSearch(value.label);
//   }, [value]);

//   React.useEffect(() => {
//     const fetchFilteredData = async () => {
//       if (value.value) {
//         try {
//           const response = await findObjectUseCase().execute(
//             schemas?.collection,
//             {}
//           ); // Fetch all branches
//           const filteredData = response.find((item) => item.id === value.value); // Match based on ID
//           if (filteredData) {
//             setOp(filteredData.name); // Display branch name in the input
//             setText(filteredData.name); // Set text to the branch name
//           } else {
//             setOp(""); // Clear if no match found
//             setText(""); // Clear text
//           }
//         } catch (error) {
//           console.error("Error fetching filtered data:", error);
//         }
//       }
//     };

//     fetchFilteredData();
//   }, [value.value]);

//   function onSelect(value, index) {
//     onChange(value, index); // Pass the selected value and index to the parent component
//     setOpen(false);
//     setSearch(value.name); // Set search to the selected branch's name
//     setOp(value.name); // Update op to display the branch name
//     setText(value.name); // Update text to the branch name
//   }

//   function _onChange(e) {
//     const value = e.target.value;
//     if (uppercase) {
//       setSearch(value.toUpperCase());
//     } else {
//       setSearch(value);
//     }
//     onSearch(value);
//   }

//   function onClick(e) {
//     if (!isOpen) {
//       e.target.blur();
//     }
//     setOpen(!isOpen || focus);
//   }

//   // function onSelect(value, index) {
//   //   onChange(value, index);
//   //   setOpen(false);
//   //   setSearch(value.name); // Show the selected branch's name
//   //   setOp(value.name); // Update the display with the branch name
//   //   setText(value.id); // Store the branch ID in `text`
//   // }

//   function _onFocus(e) {
//     if (!focus) {
//       e.target.blur();
//     }
//     onFocus(true);
//   }

//   function onClickIcon(e) {
//     if (search) {
//       setSearch(""); // Clear the search
//       setOp(""); // Clear the display
//       onClickClear(e);
//     } else if (dynamic) {
//       onClickAdd(e);
//     } else {
//       onClick(e);
//     }
//   }

//   const style = { cursor: isOpen && focus ? "text" : "default" };
//   const icon = search
//     ? "bi bi-x"
//     : dynamic
//     ? "bi bi-plus"
//     : "bi bi-chevron-down";

//   return (
//     <div
//       ref={ref}
//       style={{
//         position: "relative",
//       }}
//     >
//       <div className="input-group">
//         <input
//           onClick={onClick}
//           type="text"
//           className={classNames(className, "form-control border-end-0 pe-0")}
//           placeholder={placeholder}
//           value={op} // Bind input value to op (branch name)
//           onChange={_onChange}
//           onFocus={_onFocus}
//           style={style}
//           required={required}
//           disabled={dis}
//         />
//         <button
//           onClick={onClickIcon}
//           className="btn btn-link"
//           type="button"
//           style={{ display: dis && `none` }}
//         >
//           <i className={icon}></i>
//         </button>
//       </div>

//       {isOpen && (
//         <ul
//           className="list-group rounded bg-white"
//           style={{
//             position: "absolute",
//             top: "2.8rem",
//             width: "100%",
//             maxHeight: "20rem",
//             overflowY: "auto",
//             zIndex: 1000,
//             boxShadow: "1px 5px 15px rgba(0, 0, 0, .08)",
//             border: "1px solid rgba(0, 0, 0, .06)",
//             scrollbarWidth: "thin",
//           }}
//         >
//           {options

//             .sort((a, b) => a.label.localeCompare(b.label))
//             .map((option, index) => (
//               <li
//                 key={option.value}
//                 type="button"
//                 className="list-group-item list-group-item-action"
//                 onClick={() => onSelect(option, index)}
//               >
//                 {option?.label}
//               </li>
//             ))}
//         </ul>
//       )}
//     </div>
//   );
// }

// SelectSearchGroup.defaultProps = defaultProps;
// export default SelectSearchGroup;

// function SelectSearch({
//   className,
//   value,
//   placeholder,
//   onChange,
//   onSearch,
//   onClickAdd,
//   onClickClear,
//   onFocus,
//   focus,
//   options,
//   required,
//   dynamic,
//   uppercase,
//   dis,
//   schemas,
// }) {
//   const [search, setSearch] = React.useState("");
//   const [text, setText] = React.useState("");
//   const [op, setOp] = React.useState("");
//   const [isOpen, setOpen] = React.useState(false);
//   const ref = React.useRef(null);
//   console.log("hehe", schemas);
//   const handleClickOutside = (event) => {
//     if (ref.current && !ref.current.contains(event.target)) {
//       setOpen(false);
//       onFocus(false);
//     }
//   };

//   React.useEffect(() => {
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   React.useEffect(() => {
//     setText(value.label); // Ensure text reflects the selected branch's name
//     setSearch(value.label);
//   }, [value]);

//   React.useEffect(() => {
//     const fetchFilteredData = async () => {
//       if (value.value) {
//         try {
//           const response = await findObjectUseCase().execute(
//             schemas?.collection,
//             {}
//           ); // Fetch all branches
//           const filteredData = response.find((item) => item.id === value.value); // Match based on ID
//           if (filteredData) {
//             setOp(filteredData.name); // Display branch name in the input
//             setText(filteredData.name); // Set text to the branch name
//           } else {
//             setOp(""); // Clear if no match found
//             setText(""); // Clear text
//           }
//         } catch (error) {
//           console.error("Error fetching filtered data:", error);
//         }
//       }
//     };

//     fetchFilteredData();
//   }, [value.value]);

//   function onSelect(value, index) {
//     onChange(value, index); // Pass the selected value and index to the parent component
//     setOpen(false);
//     setSearch(value.name); // Set search to the selected branch's name
//     setOp(value.name); // Update op to display the branch name
//     setText(value.name); // Update text to the branch name
//   }

//   function _onChange(e) {
//     const value = e.target.value;
//     if (uppercase) {
//       setSearch(value.toUpperCase());
//     } else {
//       setSearch(value);
//     }
//     onSearch(value);
//   }

//   function onClick(e) {
//     if (!isOpen) {
//       e.target.blur();
//     }
//     setOpen(!isOpen || focus);
//   }

//   // function onSelect(value, index) {
//   //   onChange(value, index);
//   //   setOpen(false);
//   //   setSearch(value.name); // Show the selected branch's name
//   //   setOp(value.name); // Update the display with the branch name
//   //   setText(value.id); // Store the branch ID in `text`
//   // }

//   function _onFocus(e) {
//     if (!focus) {
//       e.target.blur();
//     }
//     onFocus(true);
//   }

//   function onClickIcon(e) {
//     if (search) {
//       setSearch(""); // Clear the search
//       setOp(""); // Clear the display
//       onClickClear(e);
//     } else if (dynamic) {
//       onClickAdd(e);
//     } else {
//       onClick(e);
//     }
//   }

//   const style = { cursor: isOpen && focus ? "text" : "default" };
//   const icon = search
//     ? "bi bi-x"
//     : dynamic
//     ? "bi bi-plus"
//     : "bi bi-chevron-down";

//   console.log("op", op);

//   return (
//     <div
//       ref={ref}
//       style={{
//         position: "relative",
//       }}
//     >
//       <div className="input-group">
//         <input
//           onClick={onClick}
//           type="text"
//           className={classNames(className, "form-control border-end-0 pe-0")}
//           placeholder={placeholder}
//           value={op} // Bind input value to op (branch name)
//           onChange={_onChange}
//           onFocus={_onFocus}
//           style={style}
//           required={required}
//           disabled={dis}
//         />
//         <button
//           onClick={onClickIcon}
//           className="btn btn-link"
//           type="button"
//           style={{ display: dis && `none` }}
//         >
//           <i className={icon}></i>
//         </button>
//       </div>

//       {isOpen && (
//         <ul
//           className="list-group rounded bg-white"
//           style={{
//             position: "absolute",
//             top: "2.8rem",
//             width: "100%",
//             maxHeight: "20rem",
//             overflowY: "auto",
//             zIndex: 1000,
//             boxShadow: "1px 5px 15px rgba(0, 0, 0, .08)",
//             border: "1px solid rgba(0, 0, 0, .06)",
//             scrollbarWidth: "thin",
//           }}
//         >
//           {options.map((option, index) => (
//             <li
//               key={option.value}
//               type="button"
//               className="list-group-item list-group-item-action"
//               onClick={() => onSelect(option, index)}
//             >
//               {option.label}
//             </li>
//           ))}
//         </ul>
//       )}
//     </div>
//   );
// }

// SelectSearch.defaultProps = defaultProps;
// export default SelectSearch;

// import React from "react";
// import classNames from "../../classNames";

// const noop = () => {};
// const defaultProps = {
//   loadOptions: noop,
//   onChange: noop,
//   onSearch: noop,
//   onClickAdd: noop,
//   onClickClear: noop,
//   onFocus: noop,
//   value: { label: "", value: "" },
//   placeholder: "Select",
//   dynamic: false,
// };

function SelectSearch({
  className,
  value,
  placeholder,
  onChange,
  onSearch,
  onClickAdd,
  onClickClear,
  onFocus,
  focus,
  options,
  required,
  dynamic,
  uppercase,
  dis,
}) {
  const [search, setSearch] = React.useState("");
  const [text, setText] = React.useState("");
  const [isOpen, setOpen] = React.useState(false);
  const ref = React.useRef(null);

  // handle click outside event
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
      onFocus(false);
    }
  };

  React.useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClickOutside);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // when value change
  React.useEffect(() => {
    setText(value.label);
    setSearch("");
  }, [value]);

  function _onChange(e) {
    const value = e.target.value;
    // Get the new character input by the user by removing the previous text from the current value.
    const character = value.replace(text, "");
    if (search === "") {
      e.target.value = character;
    }
    if (uppercase) {
      e.target.value = value.toUpperCase();
    }
    // set the new character input by the user
    setSearch(e.target.value);
    setText(e.target.value);

    onSearch(e.target.value);
  }

  function onClick(e) {
    // e.target.setSelectionRange(0, 0);
    if (!isOpen) {
      e.target.blur();
    }
    setOpen(!isOpen || focus);
  }

  function onSelect(value, index) {
    onChange(value, index);
    setOpen(false);
    if (search) {
      // reset the options if has search
      onSearch("");
    }
  }

  function _onFocus(e) {
    if (!focus) {
      e.target.blur();
    }
    onFocus(true);
  }

  function onClickIcon(e) {
    console.log("clicked");
    if (text) {
      onClickClear(e);
    } else if (dynamic) {
      onClickAdd(e);
    } else {
      onClick(e);
    }
  }

  const style = { cursor: isOpen && focus ? "text" : "default" };
  const icon = text ? "bi bi-x" : dynamic ? "bi bi-plus" : "bi bi-chevron-down";
  return (
    <div
      ref={ref}
      style={{
        position: "relative",
      }}
    >
      <div className="input-group">
        <input
          onClick={onClick}
          type="text"
          className={classNames(className, "form-control border-end-0 pe-0")}
          placeholder={placeholder}
          value={text}
          onChange={_onChange}
          onFocus={_onFocus}
          style={style}
          required={required}
          disabled={dis}
        />
        <button
          onClick={onClickIcon}
          className="btn btn-link"
          type="button"
          style={{ display: dis && `none` }}
        >
          <i className={icon}></i>
        </button>
      </div>

      {isOpen && (
        <ul
          className="list-group rounded bg-white"
          style={{
            position: "absolute",
            top: "2.8rem",
            width: "100%",
            maxHeight: "20rem",
            overflowY: "auto",
            zIndex: 1000,
            boxShadow: "1px 5px 15px rgba(0, 0, 0, .08)",
            border: "1px solid rgba(0, 0, 0, .06)",
            scrollbarWidth: "thin",
          }}
        >
          {options
            .map((option, index) => (
              <li
                key={option.value}
                type="button"
                className="list-group-item list-group-item-action"
                onClick={() => onSelect(option, index)}
              >
                {option.label}
              </li>
            ))
            .sort()}
        </ul>
      )}
    </div>
  );
}

SelectSearch.defaultProps = defaultProps;
export default SelectSearch;
