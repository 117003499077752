import BaseFormPresenter from "../../../base/BaseFormPresenter";

class UserFormPresenter extends BaseFormPresenter {
  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }

  async getObject() {
    const collection = this.view.getCollectionName();
    const id = this.object.id || this.view.getObjectId();
    console.log(this.view);
    if (id) {
      const params = { include: ["all", "branchName"] };
      try {
        this.view.showProgress();
        this.object = await this.getObjectUseCase.execute(collection, id, {
          params,
        });
        this.view.hideProgress();
        this.view.setObject(this.object);
      } catch (error) {
        this.view.hideProgress();
        this.view.showError(error);
      }
    }
  }

  // async save() {
  //   const collection = this.view.getCollectionName();
  //   if (this.object.id) {
  //     this.change.id = this.object.id;
  //     this.change.acl = this.view.getAcl();
  //   } else {
  //     this.change.role = this.change.roles[0].id;
  //     this.change.acl = this.view.getAcl();
  //     this.change.status = "ACTIVE";
  //     if (!localStorage.getItem("confirmPass")) {
  //       localStorage.setItem("confirmPass", this.change.confirmPassword);
  //     }

  //     const pass = localStorage.getItem("confirmPass");
  //     console.log("pass", pass);

  //     if (
  //       this.change.password !== pass ||
  //       this.change.password !== this.change.confirmPassword
  //     ) {
  //       console.log("changed", this.change);
  //       return this.view.showError("Confirm Password does not match!");
  //     }

  //     console.log("chnaged", this.change);
  //   }
  //   try {
  //     const pass = localStorage.getItem("confirmPass");
  //     if (this.change.password === pass) {
  //       delete this.change.confirmPassword;
  //       await this.upsertUseCase.execute(collection, this.change);
  //       localStorage.removeItem("confirmPass");
  //     }
  //     this.change = {};
  //     if (this.change.password === this.change.confirmPassword) {
  //       this.view.submissionSuccess();
  //       this.view.showSuccessSnackbar("User Created Successfully!");
  //       this.view.navigateTo("/users");
  //     }
  //   } catch (error) {
  //     throw error; // rethrow the error to be caught by the caller
  //   }
  // }

  // async save() {
  //   const collection = this.view.getCollectionName();
  //   if (this.object.id) {
  //     this.change.id = this.object.id;
  //     this.change.acl = this.view.getAcl();
  //   } else {
  //     this.change.role = this.change.roles[0].id;
  //     this.change.acl = this.view.getAcl();
  //     this.change.status = "ACTIVE";

  //     if (this.change.password !== this.change.confirmPassword) {
  //       console.log("changed", this.change);
  //       return this.view.showError("Confirm Password does not match!");
  //     }

  //     console.log("chnaged", this.change);
  //   }
  //   try {
  //     if (this.change.password === pass) {
  //       delete this.change.confirmPassword;
  //       await this.upsertUseCase.execute(collection, this.change);
  //       localStorage.removeItem("confirmPass");
  //     }
  //     this.change = {};
  //     if (this.change.password === this.change.confirmPassword) {
  //       this.view.submissionSuccess();
  //       this.view.showSuccessSnackbar("User Created Successfully!");
  //       this.view.navigateTo("/users");
  //     }
  //   } catch (error) {
  //     throw error; // rethrow the error to be caught by the caller
  //   }
  // }
  async save() {
    const collection = this.view.getCollectionName();

    if (this.object.id) {
      this.change.id = this.object.id;
      this.change.acl = this.view.getAcl();
    } else {
      this.change.role = this.change.roles[0]?.id || null;
      this.change.acl = this.view.getAcl();
      this.change.status = "ACTIVE";

      // Validate passwords
      if (this.change.password !== this.change.confirmPassword) {
        console.log("changed", this.change);
        return this.view.showError("Confirm Password does not match!");
      }
    }

    try {
      // Delete confirmPassword right before saving, ensuring validation has occurred
      const dataToSave = { ...this.change };
      delete dataToSave.confirmPassword;

      // Save the data
      await this.upsertUseCase.execute(collection, dataToSave);

      localStorage.removeItem("confirmPass");
      this.change = {}; // Reset change after successful save

      // Show success messages and navigate
      this.view.submissionSuccess();
      this.view.showSuccessSnackbar("User Created Successfully!");
      this.view.navigateTo("/users");
    } catch (error) {
      console.error(error);

      // Handle errors like "email is unique"
      if (error.message.includes("email is unique")) {
        return this.view.showError("Email already exists. Please try again.");
      }

      // Reset confirmPassword for retries
      if (!this.change.confirmPassword) {
        this.change.confirmPassword = "";
      }

      throw error; // Rethrow for the caller to handle
    }
  }

  async submit() {
    console.log("get role", this.change.roles, this.object.roles);
    if (Object.values(this.change).length === 0) {
      this.view.showSuccessSnackbar("Successfully saved!");
      return;
    }
    try {
      this.view.submitting();
      await this.save();
      // this.view.submissionSuccess();
      // this.view.showSuccessSnackbar("User Created Successfully!");
      // this.view.navigateTo("/users");
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }
}

export default UserFormPresenter;
